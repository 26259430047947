export interface IMailBulletin {
  email?: string;
  bulletinRegistrationDate?: string;
}

// tslint:disable-next-line: class-name
export class Mailbulletin implements IMailBulletin {
  constructor(
    public email?: string,
    public bulletinRegistrationDate?: string,
  ) {}
}
