import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { faTimesCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title;
  @Input() content;
  @Input() image;
  @Input() icon;
  @Input() home;
  @Input() confirm;
  @Input() cancel;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    console.log(this.image);
  }
  // tslint:disable-next-line: typedef
  colorIcon() {
    let classIcon = 'fa-3x ';
    if (this.icon === faCheckCircle) {
      classIcon += 'text-success';
    } else if (this.icon === faTimesCircle) {
      classIcon += 'text-danger';
    } else if (this.icon === faExclamationCircle) {
      classIcon += 'text-warning';
    }

    return classIcon;
  }

  // tslint:disable-next-line: typedef
  changedExistResult(home) {
    this.activeModal.close(home);
  }
}
