<div class="paral paralsec py-sm-4">
  <div class="container formWorkWithUs rounded form-shadow">
    <div class="py-4">
      <h3 class="register-heading pb-1">Línea fidelizada</h3>
      <form [formGroup]="workWithUsForm">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="fullname">Nombre Completo</label>
            <input type="text" formControlName="fullname" class="form-control" id="fullname" placeholder="Nombre Completo">
            <div *ngIf="submitted && f.fullname.errors" class="message-error">Ingrese el nombre</div>
          </div>
          <div class="form-group col-md-5">
            <label for="email">Correo electrónico</label>
            <input type="email" formControlName="email" class="form-control" id="email" placeholder="Correo electrónico">
            <div *ngIf="submitted && f.email.errors" class="message-error">
              <div *ngIf="submitted && f.email.errors.required">Ingrese el correo electrónico</div>
              <div *ngIf="submitted && f.email.errors.pattern">Ingrese un correo electrónico válido</div>
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="cellphone">Celular</label>
            <input type="text" formControlName="cellphone" class="form-control" id="cellphone" placeholder="Celular">
            <div *ngIf="submitted && f.cellphone.errors" class="message-error">Ingrese el celular</div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="vehicle">Tipo de vehículo</label>
            <select title="Selecciona un tipo" formControlName="vehicle" class="selectpicker form-control" data-style="" id="vehicle">
              <option data-tokens="{{item.typeOfVehicle}}" *ngFor="let item of weights">{{item.typeOfVehicle + ' - ' + item.loadingCapacityTon + ' TON'}}</option>
            </select>
            <div *ngIf="submitted && f.vehicle.errors" class="message-error">Selecciona el tipo de vehículo</div>
          </div>

          <div class="form-group col-md-4">
            <label for="model">Modelo</label>
            <select title="Selecciona una modalidad" formControlName="model" class="selectpicker form-control" data-style="" id="model">
              <option data-tokens="{{year}}" *ngFor="let year of listYears">{{year}}</option>
            </select>
            <div *ngIf="submitted && f.model.errors" class="message-error">Selecciona el modelo</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="form-group card text-center ">
            <div class="card-header text-center">Fotos de la matrícula del vehículo</div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardVehicleLicensePlateFront" [src]="vehicleLicensePlateFront">
                    <div class="card-body text-center">
                      <p class="card-text">Foto frontal</p>
                    </div>
                    <div class="pb-2">
                      <input #inputVehicleLicensePlateFront type="file" accept="image/*" (change)="loadFile($event, 'vehicleLicensePlateFront')" hidden>
                      <button [class]="(vehicleLicensePlateFront == defaultLicensePlateFrontImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputVehicleLicensePlateFront.click()">{{(vehicleLicensePlateFront == defaultLicensePlateFrontImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.vehicleLicensePlateFront.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardVehicleLicensePlateBack" [src]="vehicleLicensePlateBack">
                    <div class="card-body text-center">
                      <p class="card-text">Foto posterior</p>
                    </div>
                    <div class="pb-2">
                      <input #inputVehicleLicensePlateBack type="file" accept="image/*" (change)="loadFile($event, 'vehicleLicensePlateBack')" hidden>
                      <button [class]="(vehicleLicensePlateBack == defaultLicensePlateBackImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputVehicleLicensePlateBack.click()">{{(vehicleLicensePlateBack == defaultLicensePlateBackImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.vehicleLicensePlateBack.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="form-group card text-center ">
            <div class="card-header text-center">Fotos de la matrícula del trailer</div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardTrailerLicensePlateFront" [src]="trailerLicensePlateFront">
                    <div class="card-body">
                      <p class="card-text">Foto frontal</p>
                    </div>
                    <div class="pb-2">
                      <input #inputTrailerLicensePlateFront type="file" accept="image/*" (change)="loadFile($event, 'trailerLicensePlateFront')" hidden>
                      <button [class]="(trailerLicensePlateFront == defaultLicensePlateFrontImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputTrailerLicensePlateFront.click()">{{(trailerLicensePlateFront == defaultLicensePlateFrontImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.trailerLicensePlateFront.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardTrailerLicensePlateBack" [src]="trailerLicensePlateBack">
                    <div class="card-body">
                      <p class="card-text">Foto posterior</p>
                    </div>
                    <div class="pb-2">
                      <input #inputTrailerLicensePlateBack type="file" accept="image/*" (change)="loadFile($event, 'trailerLicensePlateBack')" hidden>
                      <button [class]="(trailerLicensePlateBack == defaultLicensePlateBackImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputTrailerLicensePlateBack.click()">{{(trailerLicensePlateBack == defaultLicensePlateBackImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.trailerLicensePlateBack.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="form-group card text-center ">
            <div class="card-header text-center">Fotos de los chasis</div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardChassis" [src]="chassis">
                    <div class="card-body">
                      <p class="card-text">Foto del número de chasis 1</p>
                    </div>
                    <div class="pb-2">
                      <input #inputChassis type="file" accept="image/*" (change)="loadFile($event, 'chassis')" hidden>
                      <button [class]="(chassis == defaultChassisImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputChassis.click()">{{(chassis == defaultChassisImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.chassis.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardChassis2" [src]="chassis2">
                    <div class="card-body">
                      <p class="card-text">Foto del número de chasis 2</p>
                    </div>
                    <div class="pb-2">
                      <input #inputChassis2 type="file" accept="image/*" (change)="loadFile($event, 'chassis2')" hidden>
                      <button [class]="(chassis2 == defaultChassis2Image)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputChassis2.click()">{{(chassis2 == defaultChassis2Image)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.chassis2.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="form-group card text-center ">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col py-2">
                  <div class="card" style="width: 18rem;">
                    <img [class]="classCardTruck" [src]="truck">
                    <div class="card-body">
                      <p class="card-text">Foto vehiculo y trailer</p>
                    </div>
                    <div class="pb-2">
                      <input #inputTruck type="file" accept="image/*" (change)="loadFile($event, 'truck')" hidden>
                      <button [class]="(truck == defaultTruckImage)? 'btn btn-secondary' : 'btn btn-info'" (click)="inputTruck.click()">{{(truck == defaultTruckImage)? 'Subir imagen' : 'Cambiar imagen'}}</button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.truck.errors" class="message-error text-left">Debes cargar la imagen</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="acceptTerms" id="gridCheck">
            <label class="form-check-label text-black" for="gridCheck">
              Aceptar términos y condiciones
            </label>
          </div>
          <div *ngIf="submitted && f.acceptTerms.errors" class="message-error">Debe aceptar los terminos y condicione</div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onSubmit()">Enviar solicitud</button>
      </form>
    </div>
  </div>
</div>
