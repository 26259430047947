import { Component, OnInit } from '@angular/core';
import {faEnvelope, faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faFacebook = faFacebook;
  faInstagram = faInstagram;

  constructor() { }

  ngOnInit(): void {
  }

  merchandiseTracking(): void {
    window.open('http://trazabilidad.safite.com/#/login?key=SQxMY8Tnkw6FuTPV38Zf3hPrKqJ5','_blank');
  }

  customerLogin(): void {
    window.open('http://arcargacertificados.safite.com','_blank');
  }

}
