import { AboutComponent } from './component/about/about.component';
import { WorkWithUsComponent } from './component/work-with-us/work-with-us.component';
import { PoliticsComponent } from './component/politics/politics.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { PageRoutingModule } from './page-routing.module';
import { ServiceComponent } from './component/service/service.component';
import { QuotationComponent } from './component/quotation/quotation.component';
import { HomeComponent } from './component/home/home.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';


@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    WorkWithUsComponent,
    PoliticsComponent,
    ContactUsComponent,
    ServiceComponent,
    QuotationComponent
  ],
  imports: [
    CommonModule,
    PageRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class PagesModule { }
