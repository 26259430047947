<div class="paral paralsec header">
    <div class="background-overlay"></div>
    <div class="text-content align-middle pl-5">
        <p>Transporte Masivo</p>
    </div>


</div>

<div class="card m-3">
    <div class="row ">
        <div class="col-md-4 p-4">
            <img src="../../../../assets/image/service_1.jpg" class="w-100">
        </div>
        <div class="col-md-8 px-3 my-4">
            <div class="card-block px-3">
                <h3 class="card-title">Carga General</h3>
                <p class="card-text">Transporte de materias primas, productos intermedios (carga seca), terminado, carga suelta sin embalaje, carga unitarizada, pallet, etc.</p>
            </div>
        </div>

    </div>
</div>

<div class="card m-3">
    <div class="row ">
        <div class="col-md-4 p-4">
            <img src="../../../../assets/image/service_2.jpeg" class="w-100">
        </div>
        <div class="col-md-8 px-3 my-4">
            <div class="card-block px-3">
                <h3 class="card-title">Carga a Granel</h3>
                <p class="card-text">La carga a granel es un conjunto de bienes o materiales que se transportan sin empaquetar, ni embalar, en grandes cantidades(carrocería).</p>
            </div>
        </div>

    </div>
</div>

<div class="paral paralsec header">
    <div class="background-overlay"></div>
    <div class="text-content align-middle pl-5">
        <p>Logística In House</p>
    </div>


</div>

<div class="card m-3">
    <div class="row ">
        <div class="col-md-4 p-4">
            <img src="../../../../assets/image/service_4.jpg" class="w-100">
        </div>
        <div class="col-md-8 px-3 my-4">
            <div class="card-block px-3">
                <p class="card-text">Apoyo en el eslabón de distribución de nuestros clientes, en donde las actividades son realizadas por nuestro personal directamente en los establecimientos de los clientes; optimizando así flujo e informaciones, finalmente mejorando la
                    gestión de la cadena de suministros de nuestros aliados estratégicos.</p>
            </div>
        </div>

    </div>
</div>
