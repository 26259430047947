<footer id="gtco-footer">
    <div class="overlay"></div>
    <div class="gtco-container">
        <div class="row row-pb-md">

            <div class="col-md-12 text-center">
                <div class="gtco-widget datos col-md-4">
                    <h3>DATOS</h3>
                    <h6 class="datos-small"> ARCARGA S.A.S. </h6>
                    <ul class="gtco-social-icons">
                        <li>
                            <a href="https://www.google.com/maps/dir/4.7369829,-75.9114825/Estaci%C3%B3n+de+Servicio+Brio+Cartago,+Cartago,+Valle+del+Cauca/@4.7335539,-75.9142112,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8e386fed90630e6d:0x1abbe616c6191d09!2m2!1d-75.912375!2d4.730125"
                                target="_blank"><i class="icon-faMapMarked"><fa-icon class="pl-2" [icon]="faMapMarked"></fa-icon></i>Carrera 23 Nro 9-81, Cartago Valle</a>
                        </li>
                    </ul>
                    <ul class="gtco-social-icons">
                        <li><a href="tel: +57 3166287024"><i class="icon-phone"><fa-icon class="pl-2" [icon]="faPhone"></fa-icon></i>+57 316 6287024</a></li>
                        <li><a href="tel: +57 3155515995"><i class="icon-phone"><fa-icon class="pl-2" [icon]="faPhone"></fa-icon></i>+57 315 5515995</a></li>
                        <!-- <li><a href="#"><i class="icon-chat"><fa-icon class="pl-2" [icon]="faWhatsapp"></fa-icon></i>Chat Whatsapp</a></li> -->
                    </ul>

                    <iframe src="https://www.google.com/maps/embed?pb=!4v1599016960084!6m8!1m7!1sSIb-gfF_2LUPcyrVs7UW7w!2m2!1d4.731248273335308!2d-75.91241276079722!3f196.77935323248502!4f4.16226385816546!5f0.7820865974627469" width="100%" height="200" frameborder="0" style="border:0;padding:15px"
                        allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>


                </div>

                <div class="gtco-widget enlaces col-md-4">
                    <h3> NOSOTROS </h3>
                    <ul class="gtco-social-icons nosotros">
                        <li><a href="https://arcarga.com.co/#/work-with-us"><i class=""><fa-icon class="" [icon]="faBriefcase"></fa-icon></i>Trabaja Con Nosotros</a></li><br>
                        <li><a href="https://arcarga.com.co/#/about"><i class=""><fa-icon class="" [icon]="faAddressCard"></fa-icon></i>Quiénes Somos    </a></li><br>
                        <li><a href="https://arcarga.com.co/#/service"><i class=""><fa-icon class="" [icon]="faAngellist"></fa-icon></i>Nuestros Servicios</a></li><br>
                        <li><a href="https://arcarga.com.co/#/quotation"><i class=""><fa-icon class="" [icon]="faMoneyCheck"></fa-icon></i>Cotizaciones</a></li><br>
                        <li><a href="http://trazabilidad.safite.com/#/login?key=SQxMY8Tnkw6FuTPV38Zf3hPrKqJ5" target="_blank"><i class=""><fa-icon class="" [icon]="faTruck"></fa-icon></i>Rastrea Tus Envíos</a></li><br>
                    </ul>
                </div>
                <div class="gtco-widget social col-md-4">
                    <h3> SOCIAL </h3>
                    <ul class="gtco-social-icons">
                        <!-- <li><a href="#"><i class=""><fa-icon class="" [icon]="faTwitter"></fa-icon></i></a></li> -->
                        <li><a href="https://www.instagram.com/arcarga_/" target="_blank"><i class=""><fa-icon class="" [icon]="faInstagram"></fa-icon></i></a></li>
                        <li><a href="https://www.facebook.com/Arcarga-Logistica-y-Transporte-SAS-102542211587519/" target="_blank"><i class=""><fa-icon class="" [icon]="faFacebook"></fa-icon></i></a></li>
                        <li><a href="https://www.facebook.com/Arcarga-Logistica-y-Transporte-SAS-102542211587519/" target="_blank"><i class=""><fa-icon class="" [icon]="faFacebookMessenger"></fa-icon></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12 text-center copyright">
                <p><small class="block">&copy; 2020. Todos los derechos Reservados. </small>
                    <small class="block">Arcarga <a class = "footer-url" href="http://trazabilidad.safite.com/#/login?key=SQxMY8Tnkw6FuTPV38Zf3hPrKqJ5" target="_blank">Tu carga Siempre Segura</a></small></p>
            </div>

        </div>



    </div>
</footer>