import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  quotationApiUrl = environment.functionsUrl.concat('/quotationApi/quotation');
  constructor(
    private http: HttpClient
  ) { }

  public requestQuotation(data: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.quotationApiUrl + '/request-quotation';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }
}
