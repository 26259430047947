<div class="paral paralsec">
  <div class="container px-4 px-sm-0">
    <div class="row py-5">
      <div class="col-md-offset-3 col-md-12">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingOne">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  Políticas de seguridad, salud en el trabajo y calidad
                  <span> </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
              <div class="panel-body">
                <ul style="list-style-type: disc;margin-left:8px;">
                  <li style="text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif; color: rgb(0, 0, 0); font-size: 20px;">Minimizar y prevenir la contaminaci&oacute;n ambiental, mediante la identificaci&oacute;n de los factores ambientales generadores el desarrollo de nuestra operaci&oacute;n e implementar, controles para la mitigaci&oacute;n y prevenci&oacute;n ambiental.</span></li>
                  <li style="text-align: justify;"><span style="color: rgb(0, 0, 0);"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Es deber de cada uno de los colaboradores de ARCARGA LOGISTICA Y TRANSPORTE S.A.S, directos, indirectos, contratistas, proveedores y visitantes, conocer los peligros, buscando mejorar la calidad de nuestros colaboradores, clientes, proveedores y contratistas a trav&eacute;s de la estimulaci&oacute;n de una conciencia de auto cuidado protecci&oacute;n del entorno, orientando al bienestar general y la salud de todos los entes involucrados en nuestras operaciones.</span></span></span></li>
                  <li style="text-align: justify;"><span style="color: rgb(0, 0, 0);"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Buscar la mejora continua en el desarrollo de los est&aacute;ndares de seguridad y salud en el trabajo, enfoc&aacute;ndonos a los procesos y programas implementados por nuestra compa&ntilde;&iacute;a.</span></span></span></li>
                  <li style="text-align: justify;"><span style="color: rgb(0, 0, 0);"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Asegurar la calidad integral de los servicios, con la finalidad de satisfacer las expectativas y necesidades de los asociados del negocio (clientes, proveedores, contratistas y terceras partes involucradas) que se encuentren en la cadena de suministros, impulsando la mejora continua y continuidad del negocio&nbsp;</span></span></span></li>
                  <li style="text-align: justify;"><span style="color: rgb(0, 0, 0);"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Establecer y revisar objetivos, mecanismos de capacitaci&oacute;n y control de los servicios que potencialicen la adopci&oacute;n de buenas pr&aacute;cticas de seguridad y salud en el trabajo, para minimizar la probabilidad de incidentes y accidentes de trabajo en la empresa.</span></span></span></li>
                  <li style="text-align: justify;"><span style="color: rgb(0, 0, 0);"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Evaluar y garantizar que toda situaci&oacute;n peligrosa que se detecte en las operaciones, ser&aacute; tratada de manera inmediata o suspendida de ser necesario.</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif; font-size: 20px; color: rgb(0, 0, 0);">Fomentar finalmente a todos los procesos orientados al cumplimiento y normatividad legal vigente, para acondicionamiento de nuestros servicios. Lo anterior con la meta de aumentar la satisfacci&oacute;n de nuestros aliados estrat&eacute;gicos, brindando servicios de la mas alta calidad con personal id&oacute;neo y competente.</span></li>
                </ul>
              </div>
            </div>
          </div>


          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Seguridad vial
                  <span> </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div class="panel-body">
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-family: Arial, Helvetica, sans-serif; color: rgb(22, 22, 34); font-size: 20px;">Nuestra organizaci&oacute;n brinda a todos sus clientes soluciones integrales en la cadena de suministros, estableciendo la presente pol&iacute;tica para dar cumplimiento al plan estrat&eacute;gico de seguridad vial y dem&aacute;s requisitos legales vigentes en materia de seguridad vial.</span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><br></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">En efecto, nos comprometemos a desarrollar e implantar actividades de prevenci&oacute;n y promoci&oacute;n de accidentes de tr&aacute;nsito, con la finalidad de generar un impacto de cuidado vial, cumplimiento de normas de transito y de cada uno de los roles que desempe&ntilde;as nuestros colaboradores tanto a nivel operativo y/o administrativo, garantizando un desplazamiento seguro. A su vez, el personal subcontratado, flota fidelizada son responsables de participar en las diversas actividades que se programen y desarrolle la compa&ntilde;&iacute;a, con el objetivo de mejorar la integridad f&iacute;sica, mental y social de todos los entes relacionados con el desarrollo de la actividad de transporte de carga terrestre, teniendo en cuenta los siguientes par&aacute;metros de seguridad vial:</span></span></span></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">&nbsp;</span></span></span></p>
                <ul style="list-style-type: disc;margin-left:8px;">
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Contar con la afiliaci&oacute;n al sistema general de seguridad social&nbsp;</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Cumplir con el esquema legal y contractual que Riga la normatividad colombiana para conductores y el servicio de transporte terrestre de carga</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Mantener y conservar la documentaci&oacute;n legal vigente que permita el transito del veh&iacute;culo en el territorio nacional&nbsp;</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Promover la capacitaci&oacute;n de nuestros operarios transportistas, en la aplicaci&oacute;n de t&eacute;cnicas defensivas y seguras, que protejan al conductor, el veh&iacute;culo, la carga, la sociedad y su medio ambiente, as&iacute; mismo inculcar la inteligencia vial, el respeto y la cortes&iacute;a por otros conductores en la v&iacute;a</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Los conductores que est&aacute;n participando activamente del proceso de la empresa, ser&aacute;n incluidos dentro del cronograma de actividades y capacitaciones definidos por la empresa, en donde el participar no se vuelva una obligaci&oacute;n sino una necesidad</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Esta estrictamente prohibido conducir un veh&iacute;culo, bajo los efectos de alcohol o sustancias alucin&oacute;genas. Se reserva el derecho de efectuar pruebas de alcohol y/o drogas de manera aleatoria, en cualquier momento, a cualquier persona dentro de las instalaciones de la empresa&nbsp;</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">El veh&iacute;culo es una ayuda para nuestra misi&oacute;n corporativa, por ende, ser&aacute; responsabilidad de nuestro personal, contratistas y subcontratados &nbsp;la realizaci&oacute;n de las inspecciones, mantenimiento preventivos y correctivos de sus veh&iacute;culos y maquinas, garantizando optimas condiciones de seguridad, dotaci&oacute;n y mantenimiento&nbsp;</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">Implementar actividades tendientes a prevenir y mitigar accidentes de transito ocasionados por la fatiga de los conductores y operadores en tr&aacute;nsito</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">cintur&oacute;n de seguridad</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color: rgb(22, 22, 34);">&nbsp;</span><span style="color:#161622;">El uso del cintur&oacute;n de seguridad es obligatorio para todas las personas que se desplacen con veh&iacute;culos en horas laborales, ya sea como conductor, acompa&ntilde;ante o pasajero</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">Uso de celulares: Se encuentra prohibido utilizar tel&eacute;fonos m&oacute;viles, se autoriza el uso de manos libres.</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">Es nuestra obligaci&oacute;n propiciar un ambiente laboral sano y seguro, por eso analizaremos actos y condiciones inseguras del personal de la empresa , contratistas y subcontratados que puedan incrementar el riesgo de accidentes de transito para dise&ntilde;ar planes de acci&oacute;n oportunos</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">La gerencia se comprometer&aacute; al seguimiento continuo del plan estrat&eacute;gico de seguridad vial y destinar&aacute; los recursos econ&oacute;micos, humanos y &eacute;ticos que sean necesarios para dar cumplimiento y los controles necesarios a todo el proceso de transporte, asegurando el mejoramiento continuo en todos nuestros procesos y sistemas de gesti&oacute;n</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">Los peatones deber&aacute;n hacer uso de puentes, pasos o cruces peatonales destinados para ellos, de tal forma que garanticen su seguridad y la de los dem&aacute;s usuarios de la v&iacute;a.</span></span></span></li>
                  <li style="text-align: justify;"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;"><span style="color:#161622;">Cada uno de los conductores que hagan uso de sus veh&iacute;culos para desplazamientos en misi&oacute;n, ser&aacute;n responsables del cumplimiento de las normas de tr&aacute;nsito en v&iacute;as p&uacute;blicas y privadas, por tal motivo ser&aacute;n los &uacute;nicos responsables de sanciones o procesos jur&iacute;dicos por incumplimiento de normas de tr&aacute;nsito</span></span></span></li>
                </ul>
                <p><br></p>
                <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><span style="color: rgb(22, 22, 34); font-family: Arial, Helvetica, sans-serif; font-size: 20px;">Esta pol&iacute;tica tiene el alcance sobro todos los entes relacionados en la operatividad de la empresa, independientemente de su forma de contrataci&oacute;n o vinculaci&oacute;n, incluyendo contratistas, subcontratados y terceros.</span></p>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingThree">
              <h4 class="panel-title">
                <a class="collapsed last" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Siplaft
                  <span> </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="panel-body">
                <p style="text-align: justify;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 20px;">En <span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">ARCARGA LOGISTICA Y TRANSPORTE S.A.S</span> el representante legal y todos sus colaboradores, estamos comprometidos con una operaci&oacute;n confiable y segura, dando cumplimiento a la resoluci&oacute;n 74854 del sistema integral para la prevenci&oacute;n y control del lavado de activos y financiaci&oacute;n del terrorismo.</span></p>
                <p _ngcontent-gvn-c25="" style='box-sizing: border-box; margin: 0cm; color: rgb(140, 140, 140); font-size: 16px; font-family: "Times New Roman", serif; text-align: justify;'><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">El buen nombre de <span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">ARCARGA LOGISTICA Y TRANSPORTE S.A.S</span> es uno de los principales activos y por consecuente es uno de los principales objetivos la administraci&oacute;n de riesgo (<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">LA/FT)</span>, de tal manera todos los negocios empleados por la empresa se llevar&aacute;n con personas &eacute;ticas y honestas, de donde se debe realizar una apropiada gesti&oacute;n de riesgo (<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">LA/FT)</span> / contrabando o actos de corrupci&oacute;n. Por lo que <span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">ARCARGA LOGISTICA Y TRANSPORTE S.A.S,&nbsp;</span>ha establecido las siguientes pol&iacute;ticas y lineamientos:</span></span></span></span></p>
                <p _ngcontent-gvn-c25="" style='box-sizing: border-box; margin: 0cm; color: rgb(140, 140, 140); font-size: 16px; font-family: "Times New Roman", serif; text-align: justify;'><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">&nbsp;</span></span></span></span></p>
                <ul _ngcontent-gvn-c25="" style='box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; color: rgb(140, 140, 140); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 14px; text-align: left; list-style-type: disc; margin-left: 8px;'>
                  <li _ngcontent-gvn-c25="" style="box-sizing: border-box; text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">la oportuna diligenciada por parte de los funcionarios de la empresa., en proteger la informaci&oacute;n, los bienes y los derechos confinados para transportar, siendo la garant&iacute;a ante eventuales situaciones de&nbsp;<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">LA/FT</span> /<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">CONTRABANDO /CORRUPCION</span></span></span></span></span></li>
                  <li _ngcontent-gvn-c25="" style="box-sizing: border-box; text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">cada una de las actividades desarrollados por nuestra empresa, estar&aacute;n siempre habituadas con &eacute;tica, honestidad e imparcialidad, siendo totalmente transparentes con todos los entes relacionados en la operatividad de&nbsp;<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">ARCARGA LOGISTICA Y TRANSPORTE S.A.S</span></span></span></span></span></li>
                  <li _ngcontent-gvn-c25="" style="box-sizing: border-box; text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">Analizar y verificar que la informaci&oacute;n entregadas por nuestros proveedores cumplan con las normas sobre la prevenci&oacute;n y control del lavado de activos y financiaci&oacute;n del terrorismo&nbsp;</span></span></span></span></li>
                  <li _ngcontent-gvn-c25="" style="box-sizing: border-box; text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">Establece mecanismos de Prevenci&oacute;n y Control del lavado de Activos, Financiaci&oacute;n del Terrorismo , por parte de los funcionarios que intervienen en la autorizaci&oacute;n, ejecuci&oacute;n y revisi&oacute;n de las operaciones, as&iacute; como en la vinculaci&oacute;n de personal y dem&aacute;s actividades que desarrolle nuestra empresa</span></span></span></span></li>
                  <li _ngcontent-gvn-c25="" style="box-sizing: border-box; text-align: justify;"><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">Aplicar Gesti&oacute;n del Riesgo en las operaciones, de los clientes y procesos para prevenir, controlar el Lavado de activos, Financiaci&oacute;n del terrorismo, Contrabando y/o actos il&iacute;citos relacionados con la Corrupci&oacute;n</span></span></span></span></li>
                </ul>
                <p _ngcontent-gvn-c25="" style='box-sizing: border-box; margin: 0cm 0cm 0cm 54pt; color: rgb(140, 140, 140); font-size: 16px; font-family: "Times New Roman", serif; text-align: justify;'><span style="font-family: Arial, Helvetica, sans-serif;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-size: 20px;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box;"><span _ngcontent-gvn-c25="" style="box-sizing: border-box; color: black;">&nbsp;</span></span></span></span></p>
                <p _ngcontent-gvn-c25="" style='box-sizing: border-box; margin: 0cm; color: rgb(140, 140, 140); font-size: 16px; font-family: "Times New Roman", serif; text-align: justify;'><span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; color: black; font-size: 20px;">La presente pol&iacute;tica estar&aacute; publicada, con el objetivo de darles a conocer a las personas vigiladas y no vigiladas por la super intendencia de puertos y transportes los mecanismos de Prevenci&oacute;n y Control del Lavados de Activos y Financiaci&oacute;n del terrorismo (<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">LA /FT </span>)<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">/CONTRABANDO /CORRUPCION&nbsp;</span> Aplicados por&nbsp;<span _ngcontent-gvn-c25="" style="box-sizing: border-box; font-weight: bolder;">ARCARGA LOGISTICA Y TRANSPORTE S.A.S,&nbsp;</span>en calidad de EMPRESA DE transporte de carga. &nbsp;</span></p>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingFour">
              <h4 class="panel-title">
                <a class="collapsed last" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Política de tratamiento de datos personales
                  <span> </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="panel-body">
                <a href="https://firebasestorage.googleapis.com/v0/b/arcarga-f3159.appspot.com/o/politics%2Fpolitica%20tratamiento%20de%20datos%20personales.pdf?alt=media&token=ee2d7b8d-2f81-477c-9b9f-315e59feb1d5" target="_blank"><span style="font-size: 20px;"><span style="font-family: Arial, Helvetica, sans-serif;">Ver documento</span></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

