import { Component, OnInit } from '@angular/core';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-politics',
  templateUrl: './politics.component.html',
  styleUrls: ['./politics.component.scss']
})
export class PoliticsComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;

  constructor() { }

  ngOnInit() {
  }

}
