<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button *ngIf="!(confirm && cancel)" type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="!home" class="modal-body d-flex justify-content-center">
    <div class="d-flex flex-column justify-content-center">
        <div *ngIf="icon" class="text-center">
            <fa-icon [class]="colorIcon()" [icon]="icon"></fa-icon>
        </div>
        <div *ngIf="content">
            <p>{{content}}</p>
        </div>
        <div *ngIf="image">
            <img class="w-100" [src]="image">
        </div>
    </div>
</div>

<div class="modal-footer">
    <button *ngIf="confirm" type="button" class="btn btn-outline-success" (click)="activeModal.close(true)">{{confirm}}</button>
    <button *ngIf="cancel" type="button" class="btn btn-outline-danger" (click)="activeModal.close(false)">{{cancel}}</button>
    <button *ngIf="!(confirm && cancel)" type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cerrar</button>
</div>