import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {WorkWithUsService} from '../../../shared/services/work-with-us.service';
import Constant from '../../../shared/utils/constant';
import weights from '../../../../assets/data/weights.json';
import Util from '../../../shared/utils/util';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalComponent} from '../../../shared/modal/modal/modal.component';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

declare var $: any;

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss']
})
export class WorkWithUsComponent implements OnInit, AfterViewInit {

  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  workWithUsForm: FormGroup;
  submitted = false;
  modalOptions: NgbModalOptions;
  weights = weights;
  listYears: number[];
  defaultLicensePlateFrontImage = '../../../../assets/image/tarjetapropiedad_1.jpg';
  defaultLicensePlateBackImage = '../../../../assets/image/tarjetapropiedad_1.jpg';
  defaultTruckImage = '../../../../assets/image/service_2.jpeg';
  defaultChassisImage = '../../../../assets/image/chasis_1.jpeg';
  defaultChassis2Image = '../../../../assets/image/chasis_2.jpeg';
  vehicleLicensePlateFront: string | ArrayBuffer;
  trailerLicensePlateFront: string | ArrayBuffer;
  vehicleLicensePlateBack: string | ArrayBuffer;
  trailerLicensePlateBack: string | ArrayBuffer;
  truck: string | ArrayBuffer;
  chassis: string | ArrayBuffer;
  chassis2: string | ArrayBuffer;
  styleCardGrayscale = 'card-img-top card-img-grayscale';
  styleCard = 'card-img-top';
  classCardVehicleLicensePlateFront: string;
  classCardTrailerLicensePlateFront: string;
  classCardVehicleLicensePlateBack: string;
  classCardTrailerLicensePlateBack: string;
  classCardTruck: string;
  classCardChassis: string;
  classCardChassis2: string;

  constructor(
    private formBuilder: FormBuilder,
    private workWithUsService: WorkWithUsService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.listYears = this.getModelValues();

    this.initValues();
    this.createForm();

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop'
    };
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.workWithUsForm.invalid) {
      return;
    }

    this.spinnerService.show();

    const listData: Array<any> = [];
    listData.push(this.f.vehicleLicensePlateFront.value);
    listData.push(this.f.trailerLicensePlateFront.value);
    listData.push(this.f.vehicleLicensePlateBack.value);
    listData.push(this.f.trailerLicensePlateBack.value);
    listData.push(this.f.truck.value);
    listData.push(this.f.chassis.value);
    listData.push(this.f.chassis2.value);
    listData.push({
      typeData: 'data',
      data: {
        fullname: this.f.fullname.value,
        email: this.f.email.value,
        cellphone: this.f.cellphone.value,
        vehicle: this.f.vehicle.value,
        model: this.f.model.value,
        acceptTerms: this.f.acceptTerms.value
      }
    });

    if (+Util.memorySizeOf(listData) > 9.999) {
      this.uploadOnlyFile(listData);
    } else {
      this.workWithUsService.requestWork(listData).subscribe((querySnapshotCreate: any) => {
        this.spinnerService.hide();
        this.modalRequestWorkSuccess();
        this.onReset();
      }, (error) => {
        this.spinnerService.hide();
        console.error('Error adding document: ', error);
        this.modalRequestWorkError();
      });
    }
  }

  get f(): any { return this.workWithUsForm.controls; }

  createForm(): void {
    this.workWithUsForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(Constant.emailPattern)]],
      cellphone: ['', Validators.required],
      vehicle: ['', Validators.required],
      model: ['', Validators.required],
      vehicleLicensePlateFront: [null, Validators.required],
      trailerLicensePlateFront: [null, Validators.required],
      vehicleLicensePlateBack: [null, Validators.required],
      trailerLicensePlateBack: [null, Validators.required],
      truck: [null, Validators.required],
      chassis: [null, Validators.required],
      chassis2: [null, Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  getModelValues(): number[] {
    let currentYear = new Date().getFullYear();
    const initYear = currentYear - 30;

    const listYears = [];

    while (initYear <= currentYear) {
      listYears.push(currentYear--);
    }

    return listYears;
  }

  loadFile(event, attribute: string): void {
    const file = (event.target as HTMLInputElement).files[0];

    if (file.type.match('image.*')) {
      const reader = new FileReader();
      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        if ( evt.target.readyState === FileReader.DONE) {
          const src = evt.target.result;
          switch (attribute) {
            case 'vehicleLicensePlateFront':
              this.vehicleLicensePlateFront = src;
              this.classCardVehicleLicensePlateFront = this.styleCard;
              break;
            case 'vehicleLicensePlateBack':
              this.vehicleLicensePlateBack = src;
              this.classCardVehicleLicensePlateBack = this.styleCard;
              break;
            case 'trailerLicensePlateFront':
              this.trailerLicensePlateFront = src;
              this.classCardTrailerLicensePlateFront = this.styleCard;
              break;
            case 'trailerLicensePlateBack':
              this.trailerLicensePlateBack = src;
              this.classCardTrailerLicensePlateBack = this.styleCard;
              break;
            case 'truck':
              this.truck = src;
              this.classCardTruck = this.styleCard;
              break;
            case 'chassis':
              this.chassis = src;
              this.classCardChassis = this.styleCard;
              break;
            case 'chassis2':
              this.chassis2 = src;
              this.classCardChassis2 = this.styleCard;
              break;
          }

          const data = {};
          data[attribute] = {
            image: src,
            contentType: file.type,
            type: Util.extensionFile(file.name),
            typeData: 'image',
            attributeName: attribute
          };
          this.workWithUsForm.patchValue(data);
          this.workWithUsForm.get(attribute).updateValueAndValidity();
        }
      };
    }
  }

  onReset(): void {
    this.submitted = false;
    this.workWithUsForm.patchValue({
        fullname: '',
        email: '',
        cellphone: '',
        vehicle: '',
        model: '',
        vehicleLicensePlateFront: null,
        trailerLicensePlateFront: null,
        vehicleLicensePlateBack: null,
        trailerLicensePlateBack: null,
        truck: null,
        chassis: null,
        acceptTerms: false
      }
    );

    $('.selectpicker').selectpicker('refresh');

    this.initValues();
  }

  private initValues(): void {
    this.vehicleLicensePlateFront = this.defaultLicensePlateFrontImage;
    this.trailerLicensePlateFront = this.defaultLicensePlateFrontImage;
    this.vehicleLicensePlateBack = this.defaultLicensePlateBackImage;
    this.trailerLicensePlateBack = this.defaultLicensePlateBackImage;
    this.truck = this.defaultTruckImage;
    this.chassis = this.defaultChassisImage;
    this.chassis2 = this.defaultChassis2Image;

    this.classCardVehicleLicensePlateFront = this.styleCardGrayscale;
    this.classCardTrailerLicensePlateFront = this.styleCardGrayscale;
    this.classCardVehicleLicensePlateBack = this.styleCardGrayscale;
    this.classCardTrailerLicensePlateBack = this.styleCardGrayscale;
    this.classCardTruck = this.styleCardGrayscale;
    this.classCardChassis = this.styleCardGrayscale;
    this.classCardChassis2 = this.styleCardGrayscale;
  }

  uploadOnlyFile = (listData: Array<any>) => {
    const object = listData[0];
    const data = listData.filter((obj: any) => obj.typeData === 'data')[0];
    this.workWithUsService.uploadOnlyfile(object).subscribe((querySnapshot: any) => {
      if (querySnapshot.url) {
        data.data[object.attributeName] = querySnapshot.url;
        data.data[object.attributeName + 'Path'] = querySnapshot.path;
        listData.shift();
        if (+Util.memorySizeOf(listData) > 9.999) {
          this.uploadOnlyFile(listData);
        } else {
          this.workWithUsService.requestWork(listData).subscribe((querySnapshotCreate: any) => {
            this.spinnerService.hide();
            this.modalRequestWorkSuccess();
            this.onReset();
          }, (error) => {
            this.spinnerService.hide();
            console.error('Error adding document: ', error);
            this.modalRequestWorkError();
          });
        }
      } else if (querySnapshot.error) {
      }
    }, (error) => {
      this.spinnerService.hide();
      console.error('Error adding document: ', error);
    });
  }

  modalRequestWorkSuccess(): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Trabaja con nosotros';
    modalRef.componentInstance.content = 'Su solicitud fue enviada exitosamente';
    modalRef.componentInstance.icon = this.faCheckCircle;
    modalRef.result.then(() => {
      this.onReset();
    });
  }

  modalRequestWorkError(): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Trabaja con nosotros';
    modalRef.componentInstance.content = 'Se produjo un error al realizar la solicitud. Vuelva a intentarlo más tarde.';
    modalRef.componentInstance.icon = this.faTimesCircle;
    modalRef.result.then(() => {
      this.submitted = false;
    });
  }

}
