import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import cities from '../../../../assets/data/cities.json';
import weights from '../../../../assets/data/weights.json';
import Constant from '../../../shared/utils/constant';
import {QuotationService} from '../../../shared/services/quotation.service';
import {ModalComponent} from '../../../shared/modal/modal/modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit, AfterViewInit  {

  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  quotationForm: FormGroup;
  cities = cities;
  weights = weights;
  submitted = false;
  modalOptions: NgbModalOptions;

  constructor(
    private formBuilder: FormBuilder,
    private quotationService: QuotationService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {

    this.createForm();

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop'
    };
  }

  ngAfterViewInit(): void {
    $('.selectpicker').selectpicker();
    /*setTimeout(() => {
      $('.selectpicker').selectpicker();
    },100);*/
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.quotationForm.invalid) {
      return;
    }

    this.spinnerService.show();

    // display form values on success
    const data = {
      fullname: this.f.fullname.value,
      business: this.f.business.value,
      email: this.f.email.value,
      cellphone: this.f.cellphone.value,
      phone: this.f.phone.value,
      service: this.f.service.value,
      modality: this.f.modality.value,
      vehicle: this.f.vehicle.value,
      origin: this.f.origin.value,
      destination: this.f.destination.value,
      description: this.f.description.value,
      acceptTerms: this.f.acceptTerms.value
    };
    this.quotationService.requestQuotation(data).subscribe((querySnapshotRequested: any) => {
      this.spinnerService.hide();
      this.modalRequestQuotationSuccess();
    },
    error => {
      this.spinnerService.hide();
      this.modalRequestQuotationError();
    });
  }

  get f(): any { return this.quotationForm.controls; }

  onReset(): void {
    this.submitted = false;
    this.quotationForm.patchValue({
        fullname: '',
        business: '',
        email: '',
        cellphone: '',
        phone: '',
        service: '',
        modality: '',
        vehicle: '',
        origin: null,
        destination: '',
        description: '',
        acceptTerms: false
      }
    );

    $('.selectpicker').selectpicker('refresh');
  }

  createForm(): void {
    this.quotationForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      business: [''],
      email: ['', [Validators.required, Validators.pattern(Constant.emailPattern)]],
      cellphone: [''],
      phone: [''],
      service: ['', Validators.required],
      modality: [''],
      vehicle: [''],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      description: [''],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  modalRequestQuotationSuccess(): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Cotización';
    modalRef.componentInstance.content = 'Su cotización fue enviada exitosamente';
    modalRef.componentInstance.icon = this.faCheckCircle;
    modalRef.result.then(() => {
      this.onReset();
    });
  }

  modalRequestQuotationError(): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Cotización';
    modalRef.componentInstance.content = 'Se produjo un error al realizar la solicitud. Vuelva a intentarlo más tarde.';
    modalRef.componentInstance.icon = this.faTimesCircle;
    modalRef.result.then(() => {
      this.submitted = false;
    });
  }
}
