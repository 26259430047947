import { Component, OnInit } from '@angular/core';
import {faFacebook, faFacebookMessenger, faInstagram, faWhatsapp, faTwitter, faAngellist } from '@fortawesome/free-brands-svg-icons';
import {  faPhone, faMapMarked, faBriefcase, faAddressCard, faMoneyCheck, faTruck} from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;
  faTwitter = faTwitter;
  faMapMarked = faMapMarked;
  faPhone = faPhone;
  faBriefcase = faBriefcase;
  faAngellist = faAngellist;
  faAddressCard = faAddressCard;
  faMoneyCheck = faMoneyCheck;
  faTruck = faTruck;
  faFacebookMessenger = faFacebookMessenger;

  constructor() { }

  ngOnInit() {
  }

}
