<!-- MBI Staffing Navbar -->
<nav class="navbar navbar-primary navbar-expand-lg navbar-light navbar-color">
    <a class="navbar-brand mr-auto" href="index.html"><img class="logo ml-5" src="assets/image/logo.png" /></a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="true" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse justify-content-between flex-grow-0 navbar-row" id="navbarMain">
        <ul class="navbar-nav d-flex align-items-center">
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="home">
                <a class="nav-link">Inicio</a>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="about">
                <a class="nav-link">Acerca de nosotros</a>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="service">
                <a class="nav-link">Servicios</a>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center help-center" id="dropdownHelpCenter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLinkActive="active">
                <a class="nav-link dropdown-toggle">Centro de ayuda</a>
                <div class="dropdown-menu" aria-labelledby="dropdownHelpCenter">
                    <a class="dropdown-item" (click)="customerLogin()">Login clientes - Certificados</a>
                    <a class="dropdown-item" (click)="merchandiseTracking()">Seguimiento de mercancías</a>
                </div>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="work-with-us">
                <a class="nav-link">Trabaja con nosotros</a>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="politics">
                <a class="nav-link">Políticas</a>
            </li>
            <li class="nav-item mouse-pointer d-flex align-items-center" routerLinkActive="active" routerLink="quotation">
                <a class="nav-link">Cotización</a>
            </li>
        </ul>
    </div>
</nav>
