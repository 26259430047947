import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class NewsBulletinService {
    newsBulletinApiUrl = environment.functionsUrl.concat('/newsBulletinApi/newsBulletin');
    constructor(
      private http: HttpClient
    ) { }
  public registerEmail(email: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const data: any = {
      email: email,
    };
    const url = this.newsBulletinApiUrl + '/register-email';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

  public confirmationRegisterEmail(email: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const data: any = {
      email: email,
    };
    const url = this.newsBulletinApiUrl + '/confirmation-register-email';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

  public UpdateStatusEmail(email: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const data: any = {
      status: status
    };
    const url = this.newsBulletinApiUrl + '/register-email';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }
}
