<div class="paral paralsec py-sm-4">
    <div class="container formQuotation rounded form-shadow">
        <div class="py-4">
            <h3 class="register-heading pb-1">Cotización</h3>
            <form [formGroup]="quotationForm" (ngSubmit)="onSubmit()">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="fullname">Nombre Completo</label>
                        <input type="text" formControlName="fullname" class="form-control" id="fullname" placeholder="Nombre Completo">
                        <div *ngIf="submitted && f.fullname.errors" class="message-error">Ingrese el nombre</div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="business">Empresa</label>
                        <input type="text" formControlName="business" class="form-control" id="business" placeholder="Empresa">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="email">Correo electrónico</label>
                        <input type="email" formControlName="email" class="form-control" id="email" placeholder="Correo electrónico">
                        <div *ngIf="submitted && f.email.errors" class="message-error">
                            <div *ngIf="submitted && f.email.errors.required">Ingrese el correo electrónico</div>
                            <div *ngIf="submitted && f.email.errors.pattern">Ingrese un correo electrónico válido</div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="cellphone">Celular</label>
                        <input type="text" formControlName="cellphone" class="form-control" id="cellphone" placeholder="Celular">
                    </div>
                    <div class="form-group col-md-3">
                        <label for="phone">Teléfono</label>
                        <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="Teléfono">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="service">Servicio que desea solicitar</label>
                        <select title="Selecciona un servicio" formControlName="service" class="selectpicker form-control" data-style="" id="service">
              <option value="TRANSPORTE MASIVO">TRANSPORTE MASIVO</option>
              <option value="SEGUROS">SEGUROS</option>
              <option value="ALMACENAMIENTO">ALMACENAMIENTO</option>
              <option value="Otros">OTROS</option>
            </select>
                        <div *ngIf="submitted && f.service.errors" class="invalid-feedback">
                            <div *ngIf="f.service.errors.required">Seleccione un servicio</div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="modality">Modalidad</label>
                        <select title="Selecciona una modalidad" formControlName="modality" class="selectpicker form-control" data-style="" id="modality">
              <!-- <option value=""></option> -->
              <option value="NACIONAL">NACIONAL</option>
              <option value="URBANO">URBANO</option>
              <option value="PUERTO">PUERTO</option>
            </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="vehicle">Tipo de vehículo para su solicitud</label>
                        <select title="Selecciona un tipo" formControlName="vehicle" class="selectpicker form-control" data-style="" id="vehicle">
              <option data-tokens=""></option>
              <option data-tokens="{{item.typeOfVehicle}}" *ngFor="let item of weights">{{item.typeOfVehicle + ' - ' + item.loadingCapacityTon + ' TON'}}</option>
            </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="origin">Origen</label>
                        <select title="Selecciona una ciudad" class="selectpicker form-control" data-style="" formControlName="origin" id="origin" data-live-search="true">
              <option data-tokens="{{item.city}}" *ngFor="let item of cities">{{item.city + ' - ' + item.department}}</option>
            </select>
                        <div *ngIf="submitted && f.origin.errors" class="message-error">Seleccione la ciudad origen</div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="destination">Destino</label>
                        <select title="Selecciona una ciudad" class="selectpicker form-control" data-style="" formControlName="destination" id="destination" data-live-search="true">
              <option data-tokens="{{item.city}}" *ngFor="let item of cities">{{item.city + ' - ' + item.department}}</option>
            </select>
                        <div *ngIf="submitted && f.destination.errors" class="message-error">Seleccione la ciudad destino</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="description">Observaciones</label>
                        <textarea rows="8" formControlName="description" class="form-control" id="description"></textarea>
                        <div *ngIf="submitted && f.description.errors" class="message-error">
                            <div *ngIf="f.description.errors.required">Ingrese la descripción del servicio</div>
                            <div *ngIf="f.description.errors.minlength">Ingrese por lo menos 20 caracteres</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="acceptTerms" id="gridCheck">
                        <label class="form-check-label text-black" for="gridCheck">
              Aceptar términos y condiciones
            </label>
                    </div>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="message-error">Debe aceptar los terminos y condicione</div>
                </div>
                <button type="submit" class="btn btn-primary">Solicitar cotización</button>
            </form>
        </div>
    </div>
</div>