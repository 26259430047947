import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkWithUsService {
  workApiUrl = environment.functionsUrl.concat('/workWithUsApi/workWithUs');
  constructor(
    private http: HttpClient
  ) { }

  public requestWork(listData: any): Observable<any>{
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const url = this.workApiUrl + '/request-work';
    return this.http.post(url, JSON.stringify(listData), httpOptions);
  }

  uploadOnlyfile(image: any): Observable<any> {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    
    const url = this.workApiUrl + '/upload-file-for-work';
    return this.http.post(url, JSON.stringify(image), httpOptions);
  }
}
