import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkWithUsComponent } from './component/work-with-us/work-with-us.component';
import { PoliticsComponent } from './component/politics/politics.component';
import { HomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import {ServiceComponent} from './component/service/service.component';
import {QuotationComponent} from './component/quotation/quotation.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'politics', component: PoliticsComponent },
  { path: 'work-with-us', component: WorkWithUsComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'quotation', component: QuotationComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }
