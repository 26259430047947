<div class="about-section">
    <h1>ACERCA DE ARCARGA</h1><br>
    <p>Somos una empresa orientada a la prestación de servicio público de carga terrestre a nivel nacional, siendo un gran aliado estratégico para su operación logística, buscando consolidar el proceso de transporte en altos índices de efectividad y orientación
        a la generación de valor al cliente.</p>

</div>
<div class="paral paralsec1">

    <h2 style="text-align:center;">Dirección Estratégica</h2>
    <div class="row">
        <div class="column">
            <div class="card">
                <img src="../../../../assets/svg/mision.svg" alt="Misión" class="imagestrategy">
                <div class="container">
                    <h2>Misión</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Prestar el servicio de transporte terrestre con altos indicadores de calidad y confiabilidad, enfocándonos en la generación de valor agregado Del proceso logístico de nuestros clientes.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                    <br>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="card">
                <img src="../../../../assets/svg/vision.svg" alt="Visión" class="imagestrategy">
                <div class="container">
                    <h2>Visión</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Posicionarnos en el 2023 con una participación activa en el mercado colombiano, superando todas las expectativas de nuestros clientes con un crecimiento sostenible.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                    <br>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="card">
                <img src="../../../../assets/svg/proposito.svg" alt="Proposito" class="imagestrategy">
                <div class="container">
                    <h2>Propósito</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Estar en constante evolución y mejora continua, para brindar un servicio oportuno a nuestros clientes en los compromisos adquiridos, donde nuestros actuares diarios estén bajo los valores de honestidad, eficacia e integridad.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                </div>
            </div>
        </div>
    </div>


    <h2 style="text-align:center">Valores compartidos</h2>
    <div class="row">
        <div class="column">
            <div class="card cardvalores">
                <img src="../../../../assets/svg/vocacion.svg" alt="Vocación al servicio" class="imagestrategy">
                <div class="container">
                    <h2>Vocación al servicio</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Hacemos de cada momento una verdadera experiencia. Fomentando felicidad en cada proceso de nuestros colaboradores.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                </div>
            </div>
        </div>

        <div class="column">
            <div class="card cardvalores">
                <img src="../../../../assets/svg/creatividad.svg" alt="Creatividad" class="imagestrategy">
                <div class="container">
                    <h2>Creatividad</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Tenemos la valentía de cambiar nuestro camino cuando es necesario, estando siempre abiertos al cambio para generar soluciones innovadoras y originales.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                    <br>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="card cardvalores">
                <img src="../../../../assets/svg/honestidad.svg" alt="Honestidad" class="imagestrategy">
                <div class="container">
                    <h2>Honestidad</h2>
                    <br>
                    <!-- <p class="title">Arcarga</p> -->
                    <p>Transparencia a la hora de realizar nuestros negocios, conducta recta que lleva a actuar con la verdad, lo que denota sinceridad y correspondencia en lo que se hace, en lo que se piensa y en lo que se dice.</p>
                    <!-- <p><button class="button">Contact</button></p> -->
                </div>
            </div>
        </div>
    </div>
</div>