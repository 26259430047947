import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Mailbulletin } from '../../../shared/models/newsBulletin.model';
import { NewsBulletinService } from '../../../shared/services/newsBulletin.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../shared/modal/modal/modal.component';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Constant from '../../../shared/utils/constant';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit{
  emailForm: FormGroup;
  mailbulletin: Mailbulletin;
  modalOptions: NgbModalOptions;
  faWhatsapp = faWhatsapp;
  spinnerMessage: string;
  constructor(
    private formBuilder: FormBuilder,
    private newsBulletinService: NewsBulletinService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.emailForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern(Constant.emailPattern),
        ],
      ],
    });
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    this.spinnerService.show();
    this.mailbulletin = new Mailbulletin();

    this.route.queryParams.subscribe((params) => {
      const email = params['confirmationEmail'];

      if (email) {
        this.spinnerService.show();
        this.newsBulletinService
          .registerEmail(email)
          .subscribe((querySnapshot: any) => {
            console.log('respuesta del servicio ');
            this.spinnerService.hide();
            this.modalRegister(
              '¡La subscripción al boletín se realizó correctamente!'
            );
          });
      }
    });
  }

  ngAfterViewInit(): void {
    this.spinnerService.hide();
  }

  // metodo que me guarda en la BD
  public onSaveFormConfirmation(): void {
    if (this.emailForm.valid) {
      this.spinnerService.show();
      this.spinnerMessage = 'Registrando contacto Boletín...';
      const email = this.emailForm.get('email').value.toLowerCase();
      this.newsBulletinService
        .confirmationRegisterEmail(email)
        .subscribe((querySnapshot: any) => {
          console.log('respuesta del servicio ');
          this.spinnerService.hide();
          if (querySnapshot.confirm) {
            this.modalRegister(
              '¡Por favor reviza tu correo y confirma suscripción!'
            );
          } else {
            this.modalRegister(
              '¡El correo electronico ya se encuentra suscripto en el boletín informativo!'
            );
          }
          this.onResetForm();
        });
    }
  }

  onResetForm(): void {
    this.emailForm.reset();
  }
  // busco el registro de email en la BD de boletin
  modalRegister(response: string): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Registro de Boletín Informativo';
    modalRef.componentInstance.content = response;
    modalRef.componentInstance.icon = faCheckCircle;
  }
  modalConfirmRegister(): void {
    const modalRef = this.modalService.open(ModalComponent, this.modalOptions);
    modalRef.componentInstance.title = 'Registro de Boletín Informativo';
    modalRef.componentInstance.content = '¡Correo Registrado!';
    modalRef.componentInstance.icon = faCheckCircle;
  }

  // tslint:disable-next-line: typedef
  get email() {
    return this.emailForm.get('email');
  }
}
