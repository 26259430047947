export default class Util {
  static memorySizeOf = (o: any) => {
    let bytes = 0;

    const sizeOf = (obj: any) => {
      if (obj !== null && obj !== undefined) {
        switch (typeof obj) {
          case 'number':
            bytes += 8;
            break;
          case 'string':
            bytes += obj.length * 2;
            break;
          case 'boolean':
            bytes += 4;
            break;
          case 'object':
            if (obj instanceof File) {
              bytes += obj.size;
            } else {
              const objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if (objClass === 'Object' || objClass === 'Array') {
                for (const key in obj) {
                  if (!obj.hasOwnProperty(key)) continue;
                  sizeOf(obj[key]);
                }
              } else bytes += obj.toString().length * 2;
            }
            break;
        }
      }
      return bytes;
    };

    /*const formatByteSize = (sizeBytes: any) => {
      if(sizeBytes < 1024) return sizeBytes + " bytes";
      else if(sizeBytes < 1048576) return(sizeBytes / 1024).toFixed(3) + " KiB";
      else if(sizeBytes < 1073741824) return(sizeBytes / 1048576).toFixed(3) + " MiB";
      else return(sizeBytes / 1073741824).toFixed(3) + " GiB";
    };*/

    const mbSize = (sizeBytes: any) => {
      return (sizeBytes / 1048576).toFixed(3);
    };
    return mbSize(sizeOf(o));
  }

  static extensionFile(filename: string): string {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }
}
