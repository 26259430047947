<a href="https://wa.me/573166287024?text=Me%20gustaría%20saber%20los%20servicios%20que%20ofrece%20Arcarga" class="whatsapp" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
            <img class="d-block w-100" src="../../../../assets/image/banner2.jpg" data-src="holder.js/900x400?theme=social" alt="900x400" data-holder-rendered="true">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/image/banner1.jpg" data-src="holder.js/900x400?theme=industrial" alt="900x400" data-holder-rendered="true">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="../../../../assets/image/banner3.jpg" data-src="holder.js/900x400?theme=industrial" alt="900x400" data-holder-rendered="true">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
    </a>
</div>

<div class="paral paralsec1">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 tarjetas">
                <!-- Card Wider -->
                <div class="card">
                    <!-- Card image -->
                    <div class="view view-cascade overlay">
                        <img class="card-img-top fondo-imagen" src="../../../../assets/svg/gestionIntegral.svg" alt="Card image cap">
                        <a href="#!">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!-- Card content -->
                    <div class="card-body card-body-cascade text-center">

                        <!-- Title -->
                        <h4 class="card-title"><strong>Gestión Integral Del Riesgo</strong></h4>
                        <br>
                        <!-- Text -->
                        <p class="card-text">Administramos íntegramente los riesgos, custodiando la mercancía de nuestros clientes durante la prestación del servicio
                            <br> - Seguimiento en tiempo real del estado de las cargas - seguimiento las 24 horas/ 7 días a la semana. </p>
                        <!-- <div class="card-footer"> -->
                        <!-- <small class="text-body">Arcarga - Tu Carga Siempre Segura</small> -->
                        <!-- </div> -->
                    </div>

                </div>

            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 tarjetas">
                <!-- Card Wider -->
                <div class="card">
                    <!-- Card image -->
                    <div class="view view-cascade overlay">
                        <img class="card-img-top fondo-imagen" src="../../../../assets/svg/innovacion.svg" alt="Card image cap">
                        <a href="#!">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!-- Card content -->
                    <div class="card-body card-body-cascade text-center">

                        <!-- Title -->
                        <h4 class="card-title"><strong>Innovación y Mejora Continua</strong></h4>
                        <!-- Text -->
                        <p class="card-text">Innovamos en soluciones tecnológicas para potenciar nuestro servicio como operadores. Para tal fin se cuenta, con un TSM (Transportation system managment) para todas nuestras operaciones. </p>
                        <br>
                        <!-- <div class="card-footer"> -->
                        <!-- <small class="text-body">Arcarga - Tu Carga Siempre Segura</small> -->
                        <!-- </div> -->
                    </div>
                </div>

            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 tarjetas">
                <!-- Card Wider -->
                <div class="card">
                    <!-- Card image -->
                    <div class="view view-cascade overlay">
                        <img class="card-img-top fondo-imagen" src="../../../../assets/svg/solution2.svg" alt="Card image cap">
                        <a href="#!">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!-- Card content -->
                    <div class="card-body card-body-cascade text-center">

                        <!-- Title -->
                        <h4 class="card-title"><strong>Soluciones a La Medida</strong></h4>
                        <br>
                        <!-- Text -->
                        <p class="card-text">Diseñamos soluciones en logística integral personalizada, ajustándonos a las necesidades de nuestros clientes. </p>
                        <br>
                        <br>
                        <br>
                        <!-- <div class="card-footer"> -->
                        <!-- <small class="text-body">Arcarga - Tu Carga Siempre Segura</small> -->
                        <!-- </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Third Parallax Section -->

<div class="jumbotron paral paralsec2">
    <div class="container register">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 register-left">
                <img src="../../../../assets/image/logo.png" alt="" />

                <!-- <h4>Arcarga - Tu Carga Siempre Segura!</h4> -->
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 register-right">
                <h3 class="register-heading">Boletín Informativo</h3>
                <form [formGroup]="emailForm" (ngSubmit)="onSaveFormConfirmation()">
                    <div class="row register-form">
                        <div class="col-md-9">
                            <div class="form-group">
                                <input type="email" class="selectpicker form-control" formControlName="email" placeholder="Ingresa Tu Correo Electronico" value="" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <input type="submit" class="btn btn1" value="Registrarse" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>



</div>